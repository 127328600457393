import { Component, OnInit } from '@angular/core';

import { ContentService, GknFooter } from '@services';
import { Observable, filter, map } from 'rxjs';

@Component({
    selector: 'app-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['./app-footer.component.css'],
})
/**
 * Common Footer Component
 */
export class AppFooterComponent implements OnInit {
    currentYear: number = new Date().getFullYear();
    privacyPolicy$: Observable<GknFooter>;
    termsOfUse$: Observable<GknFooter>;

    constructor(private contentService: ContentService) {}

    ngOnInit(): void {
        const footers$ = this.contentService.footers$;
        this.privacyPolicy$ = footers$.pipe(
            map((footers: GknFooter[]) => footers.find((f: GknFooter) => f.type == 'privacy_policy')),
            filter((privacyPolicy) => !!privacyPolicy),
        );

        this.termsOfUse$ = footers$.pipe(
            map((footers: GknFooter[]) => footers.find((f: GknFooter) => f.type == 'terms_of_use')),
            filter((termsOfUse) => !!termsOfUse),
        );
    }
}
