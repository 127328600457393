<div [ngClass]="{ 'tile-border': border, 'tile-vertical': vertical }" class="tile">
    <ng-container *ngIf="showImage">
        <a
            *ngIf="!videoUrl; else video"
            [href]="url"
            target="_blank"
            [attr.title]="title"
            rel="nofollow"
            class="tile-image-link tile-border-radius"
            [ngClass]="customSVGIcon ? 'tile-background-color' : ''"
            [attr.data-analytics]="dataAnalytics?.join('-')">
            <img
                [ngClass]="{
                    'tile-image': !(isFacebookNews || isCustomGenesysBlogImage) ? true : false,
                    'custom-source-image': isFacebookNews || isCustomGenesysBlogImage ? true : true,
                    'big-image': bigImage,
                    'tile-image-auto-height': hasImageAutoHeight,
                    'mat-elevation-z6': !border && hasBoxShadow,
                    'tile-image-short': isImageShort
                }"
                [attr.width]="imageWidth"
                [attr.height]="imageHeight"
                [alt]="title"
                *ngIf="!videoUrl; then image" />
            <ng-template #image>
                <!--NOTE: Allow custom image svg and custom css background-->
                <ng-container *ngIf="isCustomImage; else showDefaultImage">
                    <div
                        [ngClass]="customImageCss ? customImageCss : ''"
                        [attr.data-analytics]="dataAnalytics?.join('-')">
                    </div>
                </ng-container>
                <!--NOTE: Else show default image with a fixed background with logo as one image. If there is no image, it will default to the defaultImage-->
                <ng-template #showDefaultImage>
                    <img
                        [ngClass]="{
                            'tile-image': !(isFacebookNews && isCustomGenesysBlogImage) ? true : false,
                            'custom-source-image': isFacebookNews && isCustomGenesysBlogImage ? true : false,
                            'big-image': bigImage,
                            'tile-image-auto-height': hasImageAutoHeight,
                            'mat-elevation-z6': !border && hasBoxShadow,
                            'tile-image-short': isImageShort,
                            'tile-border-radius': !imageUrl.endsWith('svg'),
                            svg: imageUrl.endsWith('svg')
                        }"
                        [alt]="title"
                        [attr.width]="imageWidth"
                        [attr.height]="imageHeight"
                        *ngIf="imageUrl; else defaultImage"
                        [src]="imageUrl"
                        [attr.data-analytics]="dataAnalytics?.join('-')" />
                </ng-template>
            </ng-template>
        </a>
        <ng-template #video>
            <app-video-container
                [isStrapi]="isStrapiVideo"
                [containerCss]="['tile-border-radius', 'tile-box-shadow']"
                [title]="'title'"
                [iframeAllow]="'accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'"
                [embedUrl]="url"
                [dataAnalytics]="dataAnalytics">
            </app-video-container>
        </ng-template>
        <!--TODO: Pass in optional svg with no background and background color-->
        <ng-template #defaultImage>
            <div
                class="default-image"
                [ngClass]="customImageCss ? customImageCss : ''"
                [attr.data-analytics]="dataAnalytics?.join('-')">
                <!--Custom Logo SVG like announcments, article, etc.. that centered in the tile with a css background color.-->
                <ng-container *ngIf="customSVGIcon; else showGenesysLogoSVG">
                    <img [src]="customSVGIcon" [alt]="title" [attr.width]="imageWidth" [attr.height]="imageHeight" />
                </ng-container>
                <ng-template #showGenesysLogoSVG>
                    <img
                        src="/assets/icons/genesys/Adaptive G_Digital Vector-White Outline.svg"
                        width="28px"
                        height="45px"
                        alt="Genesys logo" />
                </ng-template>
            </div>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="isCustomTileLink">
        <ng-content select="[customTileLink]"></ng-content>
    </ng-container>

    <div class="padded-sides tile__content">
        <!--NOTE: margin-top-small spacing is only shown on if video or image is above the timestamp heading. Do not delete. -->
        <div *ngIf="details" class="tags-date-timestamp-heading details" [ngClass]="{ 'margin-top-small': vertical }">
            <ng-container *ngIf="icon">
                <img [src]="icon" alt="icon" width="20px" height="20px" />
            </ng-container>
            {{ details | uppercase }}
        </div>
        <ng-container *ngIf="isCustomTitleLink; else openTitleLink">
            <!--NOTE: Content can be anything in case you need the link to do be done by another component-->
            <ng-content select="[customTitleLink]"></ng-content>
        </ng-container>
        <ng-template #openTitleLink>
            <a
                *ngIf="titleLink; else defaultTitle"
                class="cursor primary-link margin-top-small margin-bottom-smaller"
                [href]="url"
                target="_blank"
                rel="noopener noreferrer nofollow"
                [attr.data-analytics]="dataAnalytics?.join('-')">
                <h2
                    class="ellipsis-2 sub-title-3 text-brand-navy"
                    [ngClass]="titleMinHeight ? 'title-min-height' : ''"
                    [innerHTML]="title | safe: 'html'"></h2>
            </a>
        </ng-template>
        <ng-template #defaultTitle>
            <h2
                class="ellipsis-2 sub-title-3 text-brand-navy margin-top-small margin-bottom-smaller"
                [innerHTML]="title | safe: 'html'"></h2>
        </ng-template>
        <p
            *ngIf="description?.length > 0"
            class="ellipsis-3 p-body margin-bottom-smaller"
            [innerHTML]="description | safe: 'html'"></p>
        <ng-container *ngIf="isCustomFooterLink; else openFooterLink">
            <!--NOTE: Content can be anything in case you need the link to do be done by another component-->
            <ng-content select="[customFooterLink]"></ng-content>
        </ng-container>
        <ng-template #openFooterLink>
            <a
                class="link-n"
                [ngClass]="hasMarginTopAuto ? 'margin-top-auto' : ''"
                [href]="url"
                target="_blank"
                rel="noopener noreferrer nofollow"
                [attr.data-analytics]="dataAnalytics?.join('-')">
                {{ urlLabel }}
            </a>
        </ng-template>
    </div>
</div>
