import { Html } from '../html/html.interface';
import { Typography, TypographyLink, TypographyLinkProps, TypographyProps } from '../typography/typography.component';

/**
 * Properties for a {@link Card} component
 */
export interface CardProps {
    id?: string;
    background?: string;
    title?: TypographyProps;
    subtitle?: TypographyProps;
    link?: TypographyLinkProps;
    margin?: string;
    padding?: string;
    width?: string;
}

/**
 * Generic card component that has a title (req'd), subtitle (opt), and link (opt).
 * Resizes with screen size.
 *
 * @param {CardProps} props the {@link CardProps} for this card component
 * @returns {HTMLElement} an {@link HTMLElement}
 */
export class Card implements Html {
    constructor(private props: CardProps) {}

    html(): HTMLElement {
        const card: HTMLElement = document.createElement('div');
        card.id = this.props.id || '';
        card.style.display = 'flex';
        card.style.flexDirection = 'column';
        card.style.alignItems = 'flex-start';
        card.style.justifyContent = 'space-around';
        card.style.margin = this.props.margin || '';
        card.style.padding = this.props.padding || '';
        card.style.width = this.props.width || '';
        card.style.height = 'fit-content';
        card.style.background = this.props.background || '#152550';
        card.style.border = '2px black solid';

        if (this.props.title) {
            const title: HTMLElement = new Typography(this.props.title).html();
            card.appendChild(title);
        }

        if (this.props.subtitle) {
            const subtitle: HTMLElement = new Typography(this.props.subtitle).html();
            card.appendChild(subtitle);
        }

        if (this.props.link) {
            const link: HTMLAnchorElement = new TypographyLink(this.props.link).html();
            card.appendChild(link);
        }

        return card;
    }
}
