<!-- make sure to subscribe to the mobile viewport before subscribing the favorites -->

<ng-container *ngIf="(isAuthenticated$ | async) === true; else unauthedCtaLayout">
    <ng-container *ngIf="favorites$ | async as favorites">
        <app-mobile-carousel
            *ngIf="isMobileViewport$ | async; else deskTopView"
            [carouselDotActiveCSS]="'blue-active'"
            [carouselItems]="favorites"
            [carouselLeftArrowCSS]="['leftRs', 'arrow-left']"
            [carouselRightArrowCSS]="['rightRs', 'arrow-right']"
            [carouselItemTemplate]="favoritesTemplate">
        </app-mobile-carousel>
        <ng-template #favoritesTemplate let-chunk="$context">
            <app-tile-layout *ngIf="chunk.length > 0; else showCta" maxColumns="3" mobileThumbnails="true">
                <li
                    *ngFor="let favorite of chunk; let i = index; trackBy: trackByFavoriteId"
                    class="margin-bottom-larger">
                    <div customTileLink class="custom-tile-link mat-elevation-z6">
                        <ng-container *ngIf="!favorite?.id?.startsWith('owtgi'); else externalTileLink">
                            <a
                                [routerLink]="favorite?.urlPath"
                                [queryParams]="{
                                    product: (selectedProduct$ | async).uid
                                }"
                                [fragment]="favorite?.id"
                                rel="nofollow">
                                <ng-container
                                    [ngTemplateOutlet]="tileThumbnail"
                                    [ngTemplateOutletContext]="favorite"></ng-container>
                            </a>
                        </ng-container>
                        <ng-template #externalTileLink>
                            <a [href]="favorite.urlPath" target="_blank" rel="nofollow">
                                <ng-container
                                    [ngTemplateOutlet]="tileThumbnail"
                                    [ngTemplateOutletContext]="favorite"></ng-container>
                            </a>
                        </ng-template>
                    </div>
                    <div class="external-link-div" customTitleLink>
                        <h2 class="p-body-small ellipsis-2 margin-top-small">
                            <ng-container *ngIf="!favorite?.id?.startsWith('owtgi'); else externalTitleLink">
                                <a
                                    class="cursor primary-link"
                                    [routerLink]="favorite?.urlPath"
                                    [queryParams]="{
                                        product: (selectedProduct$ | async).uid
                                    }"
                                    [fragment]="favorite?.id"
                                    rel="nofollow">
                                    {{ favorite.label }}
                                </a>
                            </ng-container>
                            <ng-template #externalTitleLink>
                                <a class="cursor primary-link" [href]="favorite.urlPath" rel="nofollow" target="_blank">
                                    {{ favorite.label }}
                                </a>
                            </ng-template>
                        </h2>
                    </div>
                    <app-favorite-icon
                        class="margin-top-smaller"
                        [favoriteId]="favorite?.attributes?.favoriteId"
                        [domIdSuffix]="i"></app-favorite-icon>
                </li>
            </app-tile-layout>
        </ng-template>

        <ng-template #deskTopView>
            <app-tile-layout *ngIf="favorites.length > 0; else showCta" maxColumns="4" mobileThumbnails="true">
                <li *ngFor="let favorite of favorites; let i = index; trackBy: trackByFavoriteId">
                    <app-tile-vertical
                        [showImage]="false"
                        [titleLink]="true"
                        [bigImage]="true"
                        [imageUrl]="favorite.thumbnails?.web"
                        [border]="false"
                        [vertical]="true"
                        [hasBoxShadow]="true"
                        [isCustomTileLink]="true"
                        [isCustomTitleLink]="true"
                        [isCustomFooterLink]="true"
                        [hasBoxShadow]="true"
                        (click)="scroll()">
                        <div customTileLink class="custom-tile-link mat-elevation-z6">
                            <ng-container *ngIf="!favorite?.id?.startsWith('owtgi'); else externalTileLink">
                                <a
                                    [routerLink]="favorite.urlPath"
                                    [queryParams]="{
                                        product: (selectedProduct$ | async).uid
                                    }"
                                    [fragment]="favorite?.fragment"
                                    rel="nofollow">
                                    <ng-container
                                        [ngTemplateOutlet]="tileThumbnail"
                                        [ngTemplateOutletContext]="favorite"></ng-container>
                                </a>
                            </ng-container>
                            <ng-template #externalTileLink>
                                <a [href]="favorite.urlPath" target="_blank" rel="nofollow">
                                    <ng-container
                                        [ngTemplateOutlet]="tileThumbnail"
                                        [ngTemplateOutletContext]="favorite"></ng-container>
                                </a>
                            </ng-template>
                        </div>
                        <div class="external-link-div" customTitleLink>
                            <h2 class="sub-title-3 margin-top-small margin-bottom-smaller ellipsis-2 cursor">
                                <ng-container *ngIf="!favorite.id?.startsWith('owtgi'); else externalTitleLink">
                                    <a
                                        class="cursor primary-link"
                                        [routerLink]="favorite.urlPath"
                                        [queryParams]="{
                                            product: (selectedProduct$ | async).uid
                                        }"
                                        [fragment]="favorite?.fragment"
                                        rel="nofollow">
                                        {{ favorite.label }}
                                    </a>
                                </ng-container>
                                <ng-template #externalTitleLink>
                                    <a
                                        class="cursor primary-link"
                                        [href]="favorite.urlPath"
                                        rel="nofollow"
                                        target="_blank">
                                        {{ favorite.label }}
                                    </a>
                                </ng-template>
                            </h2>
                            <app-favorite-icon
                                class="margin-top-small"
                                [favoriteId]="favorite.attributes.favoriteId"
                                [domIdSuffix]="i"></app-favorite-icon>
                        </div>
                    </app-tile-vertical>
                </li>
            </app-tile-layout>
        </ng-template>

        <ng-template #showCta>
            <div class="cta">
                <h2 class="h3 sub-title-3 text-brand-navy margin-bottom-small">
                    You haven't added anything to your {{ (selectedProduct$ | async).displayName }} favorites yet!
                </h2>
                <p class="p-body">
                    Adding favorites allows you to jump straight to the sections of the GKN that matter most to you.
                    Start favoriting content now by clicking the
                    <span>
                        <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.812881 8.45108L8.34703 7.35612L11.715 0.528203C11.807 0.341259 11.9583 0.189923 12.1453 0.0979342C12.6141 -0.133521 13.1839 0.0593584 13.4183 0.528203L16.7862 7.35612L24.3204 8.45108C24.5281 8.48076 24.718 8.57868 24.8634 8.72705C25.0392 8.90772 25.1361 9.15079 25.1327 9.40284C25.1294 9.6549 25.0261 9.89531 24.8456 10.0713L19.3946 15.3858L20.6824 22.8903C20.7126 23.0649 20.6933 23.2444 20.6266 23.4086C20.56 23.5727 20.4487 23.7149 20.3053 23.819C20.162 23.9231 19.9923 23.985 19.8156 23.9976C19.6389 24.0102 19.4622 23.973 19.3055 23.8903L12.5666 20.3473L5.82774 23.8903C5.64376 23.9882 5.43011 24.0209 5.22536 23.9853C4.70904 23.8962 4.36186 23.4066 4.45088 22.8903L5.73872 15.3858L0.287657 10.0713C0.139288 9.92587 0.0413647 9.73595 0.0116901 9.52824C-0.068428 9.00895 0.293591 8.52823 0.812881 8.45108Z" />
                        </svg>
                    </span>
                    wherever you see it on the site.
                </p>
            </div>
        </ng-template>
    </ng-container>

    <ng-template #tileThumbnail let-thumbnails="thumbnails" let-label="label">
        <ng-container *mobile>
            <img
                *ngIf="thumbnails?.mobileFavorite; else defaultThumbnail"
                [src]="thumbnails?.mobileFavorite"
                [alt]="label + ' thumbnail image'"
                width="73px"
                height="73px" />
        </ng-container>

        <ng-container *desktop>
            <img
                *ngIf="thumbnails?.web; else defaultThumbnail"
                [src]="thumbnails?.web"
                [alt]="label + ' thumbnail image'"
                width="252px"
                height="125px" />
        </ng-container>

        <ng-template #defaultThumbnail>
            <div class="default-image cursor tile-background-color">
                <img
                    src="/assets/icons/genesys/Adaptive G_Digital Vector-White Outline.svg"
                    width="28px"
                    height="45px"
                    alt="Genesys logo" />
            </div>
        </ng-template>
    </ng-template>
</ng-container>

<ng-template #unauthedCtaLayout>
    <div class="cta">
        <p class="sub-title-3 text-brand-navy margin-bottom-small">
            Missing your favorites? Please
            <button class="button-link sub-title-3 text-link-blue margin-bottom-small" (click)="openLoginModal()">
                sign-in
            </button>
            to view them.
        </p>
    </div>
</ng-template>
