import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentService, GknAlert } from '@services';

import { marked } from 'marked';
import { Subscription } from 'rxjs';
import { AlertTypes } from './alert-types.enum';

@Component({
    selector: 'app-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.css'],
})
export class AlertsComponent implements OnInit, OnDestroy {
    private subs: Subscription[] = [];
    private dismissedAlerts = [];
    private alerts: GknAlert[] = [];
    currentAlert: GknAlert;
    alertTypes = AlertTypes;
    fillColor: string;

    constructor(private contentService: ContentService) {}

    ngOnInit(): void {
        this.getDismissedAlerts();

        // this.subs.push(
        //     this.contentService.alerts$.subscribe((alerts: GknAlert[]) => {
        //         this.alerts = alerts;
        //         this.updateCurrentAlert();
        //     }),
        // );
    }

    /**
     * Updates the current alert
     */
    // TODO KNOW-1380 Refactor this function to use an observable stream
    updateCurrentAlert(): void {
        this.currentAlert = undefined;
        this.alerts.every((alert) => {
            if (!this.dismissedAlerts.includes(alert.id)) {
                const renderer = new marked.Renderer();
                const linkRenderer = renderer.link;
                renderer.link = (href, title, text) => {
                    const html = linkRenderer.call(renderer, href, title, text);
                    return html.replace(/^<a\s*/, '<a target="_blank"');
                };
                alert.rendered = marked(alert.rendered, { renderer });
                this.currentAlert = alert;

                if (this.currentAlert.color == this.alertTypes.MAINTENANCE) {
                    this.fillColor = 'var(--genesys-csdt-brand-navy)';
                } else {
                    this.fillColor = '#ffffff';
                }
                return false;
            } else {
                return true;
            }
        });
    }

    /**
     * Gets dismissed alerts from local storage
     */
    getDismissedAlerts(): void {
        const dismissed_alerts = JSON.parse(localStorage.getItem('dismissed_alerts'));
        if (dismissed_alerts) {
            this.dismissedAlerts = dismissed_alerts;
        }
    }

    /**
     * Dismisses alert by adding it to the local storage array of dismissed alerts
     * @param id The id of the alert to be dismissed
     */
    dismissAlert(id: number): void {
        if (!this.dismissedAlerts.includes(id)) {
            this.dismissedAlerts.push(id);
            this.updateCurrentAlert();
            localStorage.setItem('dismissed_alerts', JSON.stringify(this.dismissedAlerts));
        }
    }

    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }
}
