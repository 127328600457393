import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { UserClaims } from '@okta/okta-auth-js';
import {
    AuthService,
    FavoritesService,
    KeplerCoveoService,
    KeplerCredlyService,
    KeplerDoceboService,
    KeplerFacebookService,
    KeplerMarketingService,
    KeplerSfdcService,
    KeplerSfdcUserDetail,
    MarketingWpService,
} from '@services';
import { Observable, of, switchMap } from 'rxjs';
import { KeplerDbService } from '../kepler-db/kepler-db.service';
import { KeplerVimeoService } from '../kepler-vimeo/kepler-vimeo.service';

interface HealthCheckByService {
    [key: string]: HealthCheckAttributes;
}

export interface HealthCheckAttributes {
    meta: HealthCheckMetadata;
    data$: Observable<any>;
}

interface HealthCheckMetadata {
    name: string;
    url: string;
}

@Injectable({
    providedIn: 'root',
})
export class HealthCheckService {
    constructor(
        private authService: AuthService,
        private keplerFacebookService: KeplerFacebookService,
        private keplerCoveoService: KeplerCoveoService,
        private keplerMarketingService: KeplerMarketingService,
        private marketingWpService: MarketingWpService,
        private keplerCredlyService: KeplerCredlyService,
        private keplerSfdcService: KeplerSfdcService,
        private doceboService: KeplerDoceboService,
        private favoriteService: FavoritesService,
        private keplerVimeoService: KeplerVimeoService,
        private keplerDbService: KeplerDbService,
    ) {}

    public service: HealthCheckByService = {
        accessToken: {
            meta: { name: 'Access Token', url: environment.oidc.issuer },
            data$: of(this.authService.accessToken),
        },
        favorites: {
            meta: { name: 'Favorites', url: environment.api.resourceServer.favoritesUrl },
            data$: this.favoriteService.favorites$,
        },
        keplerCredlyApi: {
            meta: { name: 'Credly', url: environment.api.kepler.credly.url },
            data$: this.authService.claims$.pipe(
                switchMap((claims: UserClaims) => this.keplerCredlyService.getBadges(claims.email)),
            ),
        },
        keplerCoveoApi: {
            meta: { name: 'Coveo', url: environment.api.kepler.coveo.url },
            data$: this.keplerCoveoService.searchPage$(environment.gknSearch.pageId),
        },
        keplerFacebookApi: {
            meta: { name: 'Facebook', url: environment.api.kepler.facebook.url },
            data$: this.keplerFacebookService.getPosts(),
        },
        keplerSfdcApi: {
            meta: { name: 'Salesforce', url: environment.api.kepler.sfdc.url },
            data$: this.keplerSfdcService.sfdcUserDetail$,
        },
        keplerDoceboApi: {
            meta: { name: 'Docebo', url: environment.api.kepler.docebo.url },
            data$: this.keplerSfdcService.sfdcUserDetail$.pipe(
                switchMap((userDetail: KeplerSfdcUserDetail) =>
                    this.doceboService.enrollments$(userDetail?.Docebo_User?.docebo_v3__UserId__c),
                ),
            ),
        },
        keplerVimeoService: {
            meta: { name: 'Vimeo', url: environment.api.kepler.vimeo.url },
            data$: this.keplerVimeoService.getVideos(),
        },
        keplerDbGetProgress: {
            meta: {
                name: 'Starter Kit',
                url: environment.api.kepler.db.starterKit.url,
            },
            data$: this.authService.claims$.pipe(
                switchMap((claims: UserClaims) => this.keplerDbService.getStarterKitProgress(claims.email)),
            ),
        },
        keplerMarketingApi: {
            meta: { name: 'Kepler Marketing API', url: environment.api.kepler.marketing.url },
            data$: this.keplerMarketingService.virtualAndPhysicalEvents$,
        },
        wordpressBlogs: {
            meta: { name: 'Marketing Blogs', url: environment.feed.blog.url },
            data$: this.marketingWpService.blogs$,
        },
    };
}
