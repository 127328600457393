<header class="header">
    <!-- <app-alerts></app-alerts> -->
    <nav *ngIf="!isMobileViewport" class="main">
        <div class="top">
            <div class="logo margin-right-small">
                <!--
                    Do not remove "margin-right-small" without careful consideration! There
                    is a breakpoint between 1300px (random) and 1020px (our standard mobile
                    breakpoint) for tablet devices where the class is required for there to
                    be proper spacing between the logo and the search bar.
                -->
                <a
                    [routerLink]="homeRoutePath"
                    [queryParams]="{ product: (selectedProduct | async).uid }"
                    (click)="scrollToTop()">
                    <img
                        alt="Genesys Knowledge Network Logo"
                        title="Genesys Knowledge Network"
                        height="100%"
                        width="100%"
                        src="{{
                            isPrideMonth() ? '/assets/images/gkn-pride-logo.svg' : '/assets/images/gkn-logo-light.svg'
                        }}" />
                </a>
            </div>
            <app-search-bar *ngIf="showSearchBar$ | async" (searchClick)="handleSearch($event)"></app-search-bar>
            <div [ngClass]="!isAuthenticated ? 'icons' : 'login-icons'">
                <div class="language-translator" id="google_translate_element"></div>
                <app-announcements></app-announcements>
                <!--NOTE: Sign In click opens Login Modal-->
                <input
                    *ngIf="!isAuthenticated"
                    class="btn btn-primary"
                    type="button"
                    (click)="openLoginModal()"
                    value="Sign In" />
                <div *ngIf="isAuthenticated" class="outer-circle">
                    <a
                        class="profile"
                        [routerLink]="profileRoutePath"
                        [queryParams]="{ product: (selectedProduct | async).uid }">
                        <span translate="no">{{ initials$ | async }}</span>
                    </a>
                </div>
                <input
                    *ngIf="isAuthenticated"
                    class="btn btn-primary"
                    type="button"
                    (click)="signOut()"
                    value="Sign Out" />
            </div>
        </div>
        <div class="bottom">
            <div class="navbar-menu">
                <app-hover-menu
                    *ngFor="let routeKey of navbarRouteKeys; let i = index; trackBy: trackByItem"
                    [submenuLink]="submenuLink[i]">
                    {{ routeKey.label }}
                </app-hover-menu>
            </div>
            <app-product-select></app-product-select>
        </div>
    </nav>
    <div *ngIf="isMobileViewport" class="mobile">
        <div class="actions">
            <div class="logo">
                <a routerLink="/" (click)="scrollToTop()">
                    <img
                        alt="Genesys Knowledge Network"
                        title="Genesys Knowledge Network"
                        height="40px"
                        width="81px"
                        src="{{
                            isPrideMonth()
                                ? '/assets/images/gkn-pride-logo-mobile.svg'
                                : '/assets/images/gkn-mobile-logo.svg'
                        }}" />
                </a>
            </div>
            <app-search-bar *ngIf="showSearchBar$ | async" (searchClick)="handleSearch($event)"></app-search-bar>
            <div class="icons">
                <input
                    *ngIf="!isShowingMenu"
                    class="hamburger"
                    type="image"
                    src="/assets/icons/hamburger.svg"
                    alt="Menu icon"
                    width="30px"
                    height="30px"
                    (click)="openMenu($event)" />
                <input
                    *ngIf="isShowingMenu"
                    class="margin-left-small close"
                    type="image"
                    src="/assets/icons/close.svg"
                    alt="Close icon"
                    width="30px"
                    height="25px"
                    (click)="closeMenu($event)" />
            </div>
        </div>
    </div>
</header>
<app-support-outage-banner></app-support-outage-banner>

<!-- Mobile nav menu -->
<div #mobileMenuContainer *ngIf="isMobileViewport" class="mobile-menu-container">
    <app-mobile-viewport-menu
        [navbarRouteKeys]="navbarRouteKeys"
        (signIn)="openLoginModal()"
        (signOut)="signOut()"
        (closeMenu)="closeMenu()"
        (openMobileProfile)="handleOpenMobileProfile()"
        (cleanAndInitGoogleTranslateWidgetEmitter)="cleanAndInitGoogleTranslateWidget()">
    </app-mobile-viewport-menu>
</div>

<!--LOGIN MODAL-->
<app-login-modal
    [hideCloseButton]="true"
    [isAuthenticated]="isAuthenticated"
    [containerID]="'login-modal'"
    [modalMaxWidth]="'modal-max-width-small'"
    (signInWithOktaEmitter)="signInWithOkta()">
</app-login-modal>
<!--END LOGIN MODAL-->
