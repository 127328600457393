import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, catchError, of } from 'rxjs';
import { KeplerCredlyBadgeResponse } from './interfaces';

@Injectable({
    providedIn: 'root',
})
export class KeplerCredlyService {
    constructor(private http: HttpClient) {}

    /**
     * Gets issued badges for the given Salesforce user by contact email
     * @param email from SFDC user
     * @returns Observable array of {@link KeplerCredlyBadgeResponse}
     */
    getBadges(email: string): Observable<KeplerCredlyBadgeResponse> {
        if (!email) {
            console.error(`Failed to fetch Credly badges. Invalid email "${email}".`);
            return of({
                data: [],
                meta: {
                    count: 0,
                    current_page: 1,
                    total_count: 0,
                    total_pages: 1,
                },
            });
        }

        return this.http
            .post<KeplerCredlyBadgeResponse>(`${environment.api.kepler.credly.url}/v2/badges`, {
                recipientEmail: email,
            })
            .pipe(
                catchError((err) => {
                    console.error('Failed to fetch Credly badges.', { cause: err });
                    return of({
                        data: [],
                        meta: {
                            count: 0,
                            current_page: 1,
                            total_count: 0,
                            total_pages: 1,
                        },
                    });
                }),
            );
    }
}
