import { ViewportScroller } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router, Scroll } from '@angular/router';
import { ProductUid } from '@enums';
import { environment } from '@environments/environment';
import {
    AnalyticsService,
    ProductQueryParamService,
    ProductService,
    RouteService,
    ToastNotificationService,
    UserService,
} from '@services';
import { scrollToTopScrolling } from '@utils';
import {
    EMPTY,
    Observable,
    Subscription,
    catchError,
    combineLatest,
    combineLatestWith,
    filter,
    from,
    map,
    tap,
} from 'rxjs';
import { GknRoutePath } from './app-routing.enum';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    private subs: Subscription[] = [];

    isSearchPage$: Observable<boolean>;
    showHeroBanner$: Observable<boolean>;
    hasFragment = false;

    isAuthenticated$: Observable<boolean>;

    /** Container element to animate the opening/closing of the profile "component", not "page", in mobile */
    @ViewChild('mobileProfileContainer') mobileProfileContainer: ElementRef;

    constructor(
        private analyticsService: AnalyticsService,
        private domSanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry,
        private notificationService: ToastNotificationService,
        private productService: ProductService,
        private productQueryParamService: ProductQueryParamService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private routeService: RouteService,
        private titleService: Title,
        private userService: UserService,
        private viewRef: ViewContainerRef,
        private viewportScroller: ViewportScroller,
    ) {
        this.notificationService.viewRef = this.viewRef;
    }

    ngAfterViewInit() {
        this.subs.push(
            this.router.events
                .pipe(
                    filter((e) => e instanceof Scroll),
                    tap((e1) => {
                        const e = e1 as Scroll;
                        if (e.anchor) {
                            // anchor navigation
                            this.viewportScroller.setOffset([0, 228]); // 158px is the height of the header + 70px for the height of the section header
                            setTimeout(() => {
                                this.viewportScroller.scrollToAnchor(e.anchor);
                            }, 500);
                        } else if (e.position) {
                            // backward navigation
                            this.viewportScroller.scrollToPosition(e.position);
                        } else {
                            // forward navigation
                            this.viewportScroller.scrollToPosition([0, 0]);
                        }
                    }),
                )
                .subscribe(),
        );
    }

    ngOnInit(): void {
        if (environment.name != 'prod') {
            // Set the browser tab title for dev quality of life.
            this.titleService.setTitle(`GKN | ${environment.gitInfo.branch}`);
        }

        this.matIconRegistry.addSvgIcon(
            'genesys_g_solid',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/genesys-g-solid.svg'),
        );
        // TODO: This needs to be moved and refactored out in better way in the future.
        this.matIconRegistry.addSvgIcon(
            'facebook_icon_light',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/light/Social_Icon_Facebook_Light_Web.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'instagram_icon_light',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/light/Social_Icon_Instagram_Light_Web.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'linkedin_icon_light',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/light/Social_Icon_LinkedIn_Light_Web.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'wordpress_icon_light',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/light/Social_Icon_WordPress_Light_Web.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'youtube_icon_light',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/light/Social_Icon_Yuutube_Light_Web.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'x_icon_light',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/light/Social_Icon_X_Light_Web.svg'),
        );
        this.analyticsService.initialize();

        this.isAuthenticated$ = this.userService.isAuthenticated$;

        this.subs.push(
            combineLatest([this.userService.claims$, this.userService.sfdcUserDetail$])
                .pipe(
                    tap(([claims, sfdcUserDetail]) => {
                        return from(this.analyticsService.identify(claims?.email, sfdcUserDetail));
                    }),
                    catchError((err, _caught) => {
                        console.error(err);
                        return EMPTY;
                    }),
                )
                .subscribe(),
        );

        this.isSearchPage$ = this.routeService.currentRoute$.pipe(
            map((currentRoute) => currentRoute === GknRoutePath.SEARCH),
        );

        this.observeProductQueryParamChanges();

        this.showHeroBanner$ = this.routeService.currentRoute$.pipe(
            map((route) => route == GknRoutePath.HOME),
            combineLatestWith(this.userService.isAuthenticated$),
            map(([isHomePage, isAuthenticated]) => isHomePage && !isAuthenticated),
        );

        this.subs.push(
            this.router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe((event: NavigationEnd) => {
                    const url: string = event.urlAfterRedirects;

                    if (url.includes('#')) {
                        this.hasFragment = true;
                    }

                    if (url && url != '/') {
                        if (!this.activatedRoute.snapshot.queryParams['product']) {
                            this.subs.push(
                                this.userService.subscribedProducts$.subscribe((products) => {
                                    let queryParam = ProductUid.GENESYS_CLOUD_CX;
                                    if (products.length) {
                                        queryParam = products[0].uid;
                                    }
                                    const navigationExtras: NavigationExtras = {
                                        queryParams: { product: queryParam },
                                        queryParamsHandling: 'merge',
                                        preserveFragment: true,
                                    };
                                    this.router.navigate([], navigationExtras);
                                }),
                            );
                        }
                    }
                }),
        );
    }

    /**
     * Detects browser query parameter changes specific to the `product` key,
     * then subsequently sets the selected product in the app.
     *
     * This function has several flows and is "recursive" in nature.
     * - If a query param is not present, one will be set (to either the user's
     * default product or to Genesys Cloud). Once that parameter is set, this
     * method then detects that and sets the selected product.
     * - If a query param is present, then it is first validated before setting
     * the selected product
     *
     * Enable trace logging in your browser to follow changes within this function
     */
    private observeProductQueryParamChanges() {
        this.subs.push(
            combineLatest([
                this.productQueryParamService.productQueryParam$,
                this.userService.subscribedProducts$, // subscribed products > 0 implies that this is an auth'ed user
            ]).subscribe(([productQueryParam, subscribedProducts]) => {
                console.debug('app component detected product query param', productQueryParam);
                if (productQueryParam) {
                    const isProductQueryParamValid = Object.values(ProductUid).some((uid) => uid === productQueryParam);
                    if (isProductQueryParamValid) {
                        console.debug(
                            `valid product query param present, emitting ${productQueryParam} throughout app`,
                        );
                        // This is the only condition in which we should set the selected product!
                        this.productService.setSelectedProduct(productQueryParam);
                    } else {
                        console.debug(
                            `invalid product query param ${productQueryParam} replaced with ${ProductUid.GENESYS_CLOUD_CX}`,
                        );
                        this.productQueryParamService.setProductQueryParam(ProductUid.GENESYS_CLOUD_CX);
                    }
                } else if (subscribedProducts.length > 0) {
                    const defaultProductUid: ProductUid = subscribedProducts[0].uid;
                    this.productQueryParamService.setProductQueryParam(defaultProductUid);
                } else {
                    console.debug(`undefined product query param replaced with ${ProductUid.GENESYS_CLOUD_CX}`);
                    this.productQueryParamService.setProductQueryParam(ProductUid.GENESYS_CLOUD_CX);
                }
            }),
        );
    }

    /**
     * Scrolls the window to the top of the page.
     */
    scrollToTop(): void {
        if (!this.hasFragment) scrollToTopScrolling();
    }

    openMobileProfile(): void {
        this.mobileProfileContainer?.nativeElement.classList.add('active');
    }

    closeMobileProfile(): void {
        this.mobileProfileContainer?.nativeElement.classList.remove('active');
    }

    ngOnDestroy(): void {
        this.subs?.forEach((sub) => sub?.unsubscribe());
    }
}
