<footer class="footer-container">
    <app-back-to-top></app-back-to-top>
    <!--NEW GENESYS FOOTER-->
    <div class="copyright-footer">
        <div class="footer-row">
            <!--GENESYS CORPORATE LINKS-->
            <div class="copyright-links">
                <span class="genesys-logo-container">
                    <a
                        target="_blank"
                        rel="nofollow"
                        title="Genesys"
                        href="https://www.genesys.com"
                        aria-label="Genesys">
                        <img
                            class="genesys_logo"
                            src="/assets/icons/genesys/Adaptive G_Digital Vector-Orange.svg"
                            width="166px"
                            height="40px"
                            alt="Genesys logo" />
                    </a>
                </span>
                <span class="margin-left-small p-body-alt no-wrap copyright" data-testId="copyright">
                    <!--COPYRIGHT LINK-->
                    <span id="copyright-genesys"
                        >Copyright &copy; {{ currentYear }}
                        <a
                            id="genesys-link"
                            class="neutral-link no-wrap"
                            rel="nofollow"
                            href="https://www.genesys.com"
                            target="_blank">
                            Genesys.
                        </a></span
                    >
                    <span id="all-rights-reserved">All rights reserved.</span>
                    <span id="additional-terms" class="terms-of-use-link">
                        <!--GENESYS TERMS OF USE LINK-->
                        <ng-container *ngIf="termsOfUse$ | async as termsOfUse">
                            <a
                                id="terms-of-use-link"
                                class="margin-left-small neutral-link no-wrap"
                                rel="nofollow"
                                [href]="termsOfUse.url"
                                target="_blank">
                                {{ termsOfUse.label }}
                            </a>
                        </ng-container>
                        <!--GENESYS PRIVACY POLICY LINK-->
                        <ng-container *ngIf="privacyPolicy$ | async as privacyPolicy">
                            <a
                                id="privacy-policy-link"
                                class="margin-left-small neutral-link no-wrap"
                                rel="nofollow"
                                [href]="privacyPolicy.url"
                                target="_blank">
                                {{ privacyPolicy.label }}
                            </a>
                        </ng-container>
                    </span>
                </span>
            </div>
            <div class="socialot-sdk-btn.ot-sdk-show-settings-media-links">
                <ng-container *ngTemplateOutlet="socialmedia"></ng-container>
            </div>
        </div>
    </div>
</footer>

<ng-template #socialmedia>
    <div class="logos">
        <ul class="social-media">
            <li>
                <a
                    class="footer-icon"
                    target="_blank"
                    title="Visit us on LinkedIn"
                    rel="nofollow"
                    href="https://www.linkedin.com/company/601919?trk=tyah"
                    aria-label="Genesys LinkedIn">
                    <!-- <mat-icon svgIcon="linkedin_icon_light"></mat-icon> -->
                    <span class="icon linkedin-icon"></span>
                </a>
            </li>
            <li>
                <a
                    class="footer-icon"
                    target="_blank"
                    rel="nofollow"
                    title="Visit us on Instagram"
                    href="https://www.instagram.com/genesyscx/"
                    aria-label="Genesys Instagram">
                    <!-- <mat-icon svgIcon="instagram_icon_light"></mat-icon> -->
                    <span class="icon instagram-icon"></span>
                </a>
            </li>
            <li>
                <a
                    class="footer-icon"
                    target="_blank"
                    title="Visit us on X"
                    rel="nofollow"
                    href="https://x.com/Genesys"
                    aria-label="Genesys X">
                    <!-- <mat-icon svgIcon="x_icon_light"></mat-icon> -->
                    <span class="icon x-icon"></span>
                </a>
            </li>
            <li>
                <a
                    class="footer-icon"
                    target="_blank"
                    rel="nofollow"
                    title="Visit us on Facebook"
                    href="https://www.facebook.com/Genesys/"
                    aria-label="Genesys Facebook">
                    <!-- <mat-icon svgIcon="facebook_icon_light"></mat-icon> -->
                    <span class="icon facebook-icon"></span>
                </a>
            </li>
            <li>
                <a
                    class="footer-icon"
                    target="_blank"
                    title="Visit us on YouTube"
                    rel="nofollow"
                    href="https://www.youtube.com/Genesys"
                    aria-label="Genesys YouTube">
                    <!-- <mat-icon svgIcon="youtube_icon_light"></mat-icon> -->
                    <span class="icon youtube-icon"></span>
                </a>
            </li>
            <li>
                <a
                    class="footer-icon"
                    target="_blank"
                    title="Visit us on Genesys Blog"
                    rel="nofollow"
                    href="https://www.genesys.com/blog"
                    aria-label="Genesys Blog">
                    <!-- <mat-icon svgIcon="wordpress_icon_light"></mat-icon> -->
                    <span class="icon blog-icon"></span>
                </a>
            </li>
        </ul>
    </div>
</ng-template>
