<mat-form-field class="gkn-select" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <mat-select
        [formControl]="control"
        [disableOptionCentering]="true"
        placeholder="Select"
        [multiple]="multi"
        [panelClass]="multi ? 'multi' : ''">
        <mat-option *ngFor="let option of options" [value]="option.value">
            {{ option.text }}
        </mat-option>
    </mat-select>
</mat-form-field>
